import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import MovingElement from "@elements/MovingElement"

const CardContainer = styled.section`
  ${tw`relative z-10 grid grid-cols-1  md:grid-cols-2 pt-c60 md:pt-c96 2xl:pt-c120`}
`
const Card = styled.div`
  ${tw`relative flex flex-col justify-center w-full row-span-1 pt-6 pb-6  md:min-h-20vw min-h-50vw px-offset md:px-offsetMd lg:px-16 xl:px-24 md:pt-10 md:row-span-2`}
`
const TitleCard = styled.div`
  ${tw`relative z-10 flex flex-col justify-center w-full pb-6  md:min-h-10vw min-h-20vw px-offset md:px-offsetMd lg:px-16 xl:px-24`}
`

const CardHeadline = styled.div`
  ${tw`relative z-10 font-black text-center text-black uppercase  font-ubuntu md:text-left fhd:text-center xl:pl-8 3xl:pl-16 fhd:pr-32`}
`
const CardDescription = styled.div`
  ${tw`relative z-10 mx-auto text-xs font-bold text-center text-black  sm:text-sm font-firacode sm:leading-relaxed pt-c25 md:pt-c20 2xl:pt-c25 sm:w-2/3 xl:w-7/12 md:mr-offsetMd 2xl:mr-20 md:ml-auto`}
`
const StepSymbol = styled.div`
  ${tw`font-bold text-center  text-yellow font-firacode text-10xl sm:text-12xl 2xl:text-14xl`}
`

const WpAcfStepCardsModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfStepCardsBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `steps-section-${Math.floor(Math.random() * 100)}`

  return (
    <CardContainer
      id={`${sectionAttributes.anchor || defaultId}`}
      className={`grid-rows-4`}
    >
      <TitleCard>
        {blockData.introHeadline !== null && (
          <h2 className="relative font-bold headline-section font-ubuntu">
            <span
              className="relative"
              data-start-symbol={blockData.startSymbol}
              data-end-symbol={blockData.endSymbol}
            >
              {parse(blockData.introHeadline)}
            </span>
          </h2>
        )}
        {blockData.introSubline !== null && (
          <p className="pt-4 subline-section lg:w-2/3">
            {parse(blockData.introSubline)}
          </p>
        )}
      </TitleCard>
      {blockData.cards.map((card, i) => {
        if (card === null) return ""
        return (
          <Card key={`step-card-${i}`}>
            <CardHeadline className="headline-card">
              <span className="relative">
                {parse(card.headline)}
                <span className="normal-case symbol-code symbol-code__start text-lightGrey">
                  {card.startSymbol}
                </span>
                <span className="normal-case symbol-code symbol-code__end text-lightGrey">
                  {card.endSymbol}
                </span>
              </span>
            </CardHeadline>
            <CardDescription className="copy">
              {parse(card.description)}
            </CardDescription>
            <MovingElement
              speed={0.02 * (i + 1)}
              direction={{
                negative: "-",
                axis: "y",
              }}
              content={false}
              className="absolute z-0 left-0 right-0 mx-auto top-[15%] transition-all duration-300 ease-linear"
            >
              {card.stepSymbol && (
                <StepSymbol>{parse(card.stepSymbol)}</StepSymbol>
              )}
            </MovingElement>
          </Card>
        )
      })}
    </CardContainer>
  )
}

WpAcfStepCardsModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfStepCardsModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfStepCardsModuleBlock
